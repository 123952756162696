import '../terms.css';

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactDOM from 'react-dom';

function TermsAndConditions() {
  const [accepted, setAccepted] = useState(false);

  const handleCheckboxChange = () => {
    setAccepted(!accepted);
  };

  const navigate = useNavigate();

  const handleNextClick = () => {
    if (accepted) {
      alert('You have accepted the terms and conditions.');
      navigate("/welcome"); 
      // Proceed to the next step
    } else {
      alert('Please accept the terms and conditions before proceeding.');
    }
  };

  return (
    <div className="main"> 
    <div className="terms-container">
      <h1>TERMS & CONDITION OF MAIL GLEAM</h1>
      <p className="terms-text">
      <ol>
      <li> <b>Introduction:</b>  Welcome to Mail Gleam. These Terms and Conditions govern your use of our email categorization service. By accessing or using Mail Gleam, you agree to comply with and be bound by these terms.</li>

      <li> <b>Use of Service:</b> Mail Gleam provides tools to help you categorize and manage your emails based on user-defined rules. By using our service, you agree to use it solely for lawful purposes and in accordance with these Terms and Conditions.</li>

      <li> <b>User Accounts:</b> To use Mail Gleam, you may need to create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.</li>

      <li> <b>User-Defined Rules:</b> Mail Gleam allows you to create custom rules for email categorization. You are responsible for the accuracy and appropriateness of these rules. We are not liable for any misclassification or errors resulting from user-defined rules.</li>

      <li> <b>Privacy and Data Protection:</b> We are committed to protecting your privacy. Our Privacy Policy outlines how we collect, use, and protect your personal information. By using Mail Gleam, you consent to the practices described in our Privacy Policy.</li>

      <li> <b>Intellectual Property:</b> Mail Gleam and its content, features, and functionality are owned by us and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws. You may not reproduce, distribute, modify, or create derivative works of any part of Mail Gleam without our prior written consent.</li>

      <li> <b>Prohibited Activities:</b><br />
          You agree not to:
          <ul>
            <li>Use Mail Gleam for any illegal purpose or in violation of any local, state, national, or international law.</li>
            <li>Interfere with or disrupt the operation of Mail Gleam or its servers or networks.</li>
            <li>Use any automated system, including without limitation “robots,” “spiders,” or “offline readers,” to access Mail Gleam.</li>
            <li>Attempt to gain unauthorized access to any part of Mail Gleam, other user accounts, or computer systems or networks connected to Mail Gleam.</li></ul></li>

      <li> <b>Termination:</b> We reserve the right to terminate or suspend your access to Mail Gleam at our sole discretion, without notice, if you violate these Terms and Conditions or for any other reason. Upon termination, your right to use Mail Gleam will immediately cease. </li>

      <li> <b>Disclaimer of Warranties:</b> Mail Gleam is provided “as is” and “as available” without warranties of any kind, either express or implied. We do not warrant that Mail Gleam will be uninterrupted, error-free, or free of viruses or other harmful components. </li>

      <li> <b>Limitation of Liability:</b> To the fullest extent permitted by law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use Mail Gleam; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any bugs, viruses, trojan horses, or the like that may be transmitted to or through Mail Gleam by any third party. </li>

      <li> <b>Indemnification:</b> You agree to indemnify, defend, and hold harmless Mail Gleam and its affiliates, officers, agents, employees, and partners from any claim, liability, expense, or damage, including reasonable attorneys’ fees, arising out of or related to your use of Mail Gleam or any violation of these Terms and Conditions. </li>

      <li> <b>Governing Law:</b> These Terms and Conditions shall be governed by and construed in accordance with the laws of the jurisdiction in which Mail Gleam operates, without regard to its conflict of law principles. </li>

      <li> <b>Changes to Terms and Conditions:</b> We reserve the right to modify these Terms and Conditions at any time. We will notify you of any changes by posting the new Terms and Conditions on our website. Your continued use of Mail Gleam after any such changes constitutes your acceptance of the new Terms and Conditions.</li>

        <li> <b>Contact Information:</b> If you have any questions about these Terms and Conditions, please contact us at support@mailgleam.com.</li>
      </ol>

      <b>By using Mail Gleam, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.</b>
            </p>
            <div className="checkbox-container">
              <input 
                type="checkbox" 
                id="terms-checkbox" 
                checked={accepted} 
                onChange={handleCheckboxChange} 
              />
              <label htmlFor="terms-checkbox">READ & ACCEPT TERMS & CONDITIONS.</label>
            </div>
            <button
              className={`next-button ${accepted ? '' : 'disabled'}`} 
              onClick={handleNextClick}
            >
              Next
            </button>
          </div>
          </div>
  );
}

export default  TermsAndConditions;
