import './App.css';
import Login from './pages/login';
import Term from './pages/terms';
import Outlook from './pages/outlook';
import Yahoo from './pages/yahoo';
import Gmail from './pages/gmail';
import Welcome from './pages/welcome';
import { Route, Routes } from 'react-router-dom';
import Dashboard from './pages/dashboard';

function App() {
  return (
    <Routes>
      <Route path="/outlook" element={<Outlook />} />
      <Route path="/welcome" element={<Welcome />} />
      <Route path="/gmail" element={<Gmail />} />
      <Route path="/yahoo" element={<Yahoo />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/" element={<Login />} />
      <Route path="/terms" element={<Term />} />
    </Routes>
  );
}

export default App;
