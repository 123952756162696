import '../terms.css';
import yahoo from '../yahoo logo.png';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Welcome() {
  const [accepted, setAccepted] = useState(false);

  const handleCheckboxChange = () => {
    setAccepted(!accepted);
  };

  const navigate = useNavigate();

  const handleNextClick = (route) => {
    if (accepted) {
      alert('You have accepted the terms and conditions.');
      navigate(route); 
      // Proceed to the next step
    } else {
      alert('Please accept the terms and conditions before proceeding.');
    }
  };

  return (
    <div className="welcome-container">
      <h1> WELCOME TO MAIL GLEAM</h1>
      <p className='txt-welcome'>
        <b>INTRODUCTION:</b> Mail Gleam empowers users to create custom rules for email categorization. Whether you need to sort emails by sender, subject, keywords, or other criteria, Mail Gleam provides a flexible rule-setting interface that adapts to your unique needs. <br /><br />

        <b>SMART FILTERING:</b> Using advanced algorithms, Mail Gleam intelligently filters and categorizes incoming emails in real time. This ensures that your emails are organized and accessible without manual intervention, saving you valuable time. <br /><br />

        <b>PRIORITY MANAGEMENT:</b> With Mail Gleam, you can set priority levels for different types of emails. Important emails are flagged and brought to your immediate attention, while less critical messages are sorted into appropriate folders for later review. <br /> <br />
      </p>
      <div className="checkbox-container">
        <input 
          type="checkbox" 
          id="terms-checkbox" 
          checked={accepted} 
          onChange={handleCheckboxChange} 
        />
        <label htmlFor="terms-checkbox">READ & ACCEPT TERMS & CONDITIONS.</label>
      </div>

      <div className='row-btn'>
        <button 
          className={`login-button-google ${accepted ? '' : 'disabled'}`} 
          onClick={() => handleNextClick('/gmail')}
        >
          <img src="https://img.icons8.com/color/16/000000/google-logo.png" alt="Gmail Icon" />
          CONNECT
        </button>

        <button 
          className={`login-button-outlook ${accepted ? '' : 'disabled'}`} 
          onClick={() => handleNextClick('/outlook')}
        >
          <img src="https://img.icons8.com/?size=48&id=117562&format=png" width="30px" alt="Outlook Icon" />
          CONNECT
        </button>

        <button 
          className={`login-button-yahoo ${accepted ? '' : 'disabled'}`} 
          onClick={() => handleNextClick('/yahoo')}
        >
          <img src={yahoo} width="30px" alt="Yahoo Icon" />
          CONNECT
        </button>
      </div>
    </div>
  );
}

export default Welcome;
