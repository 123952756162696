import React from 'react';
import '../NewEmailPopup.css';

const NewEmailPopup = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="Imappopup new-email-popup">
        <div className="popup-header">
            <div></div>
          <h2 className='set-head'>New Email Account</h2>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="popup-content">
          <h3 className='set-head'>Incoming Server</h3>
          <div className="form-group fielddiv">
            <label>Username</label>
            <input type="text" placeholder="Enter username" />
          </div>
          <div className="form-group fielddiv">
            <label>Server Name</label>
            <input type="text" placeholder="Enter server name" />
          </div>

          <div className='row-flx'>
          <div className="form-group jd">
            <label>Type</label>
            <select>
              <option value="imap">IMAP</option>
              <option value="pop3">POP3</option>
            </select>
          </div>
          <div className="form-group sd">
            <label>Port</label>
            <select>
                <option>Auto</option>
            </select>
          </div>
          </div>
          
          <div className='row-flx'>
          <div className="form-group jd">
            <label>Security</label>
            <select>
              <option value="ssl">SSL</option>
              <option value="tls">TLS</option>
            </select>
          </div>
          <div className="form-group sd">
          <label> </label>
            <select className='sd'>
              <option value="yes">Autodetect</option>
            </select>
          </div>
          </div>

          <h3 className='set-head'>Outgoing Server</h3>
          <div className="form-group fielddiv">
            <label>Username</label>
            <input type="text" placeholder="Enter username" />
          </div>
          <div className="form-group fielddiv">
            <label>Server Name</label>
            <input type="text" placeholder="Enter server name" />
          </div>

          <div className='row-flx'>
          <div className="form-group jd">
            <label>Type</label>
            <select disabled >
              <option value="imap">SMTP</option>
            </select>
          </div>
          <div className="form-group sd">
            <label>Port</label>
            <select>
                <option>Auto</option>
            </select>
          </div>
          </div>
          
          <div className='row-flx'>
          <div className="form-group jd">
            <label>Security</label>
            <select>
              <option value="ssl">SSL</option>
              <option value="tls">TLS</option>
            </select>
          </div>
          <div className="form-group sd">
          <label> </label>
            <select className='sd'>
              <option value="yes">Password</option>
            </select>
          </div>
          </div>
        
        <div className='sds'>
            <div className='iwk'></div>
          <div className="imapbtn-group">
            <button className="cancel-button" onClick={onClose}>Cancel</button>
            <button className="add-button">OK</button>
        </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default NewEmailPopup;
