import React, { useState } from 'react';
import './ManageEmailPopup.css';
import { useNavigate } from 'react-router-dom';
import Acclogin from './acclogin';

const ManageEmailPopup = ({ isOpen, onClose }) => {

      const [isPopupOpen, setIsPopupOpen] = useState(false);

      const openPopup = () => {
        setIsPopupOpen(true);
      };
    
      const closePopup = () => {
        setIsPopupOpen(false);
      };

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup">
        <div className="popup-header">
          <div className=''></div>
          <h2 className='set-headp'>Manage Email</h2>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="popup-content">
          <div className="form-group">
            <label htmlFor="emailDropdown">Select Email Account:</label>
            <div className='emailbtn'>
            <select id="emailDropdown" className="dropdown">
                <option value="email1@example.com">abc@gmail.com</option>
                <option value="email2@example.com">ghi@outlook.com</option>
                <option value="email3@example.com">def@imap-email.com</option>
            </select>
            <button className="select-button">Select</button>
            </div>
          </div>

          <div className="email-config-box">
            <h3 className='set-headp'>Email Rule Configuration List</h3>
            <p>abc@gmail.com <b>from</b> job@acme.com, placement@acme.com <b>sub</b> Hiring, Recruitment &nbsp;&nbsp;&nbsp; <a href='#' className='linkview'> View More </a></p>
            <p>ghi@microsoft.com <b>from</b> newsletter@axes.com, promotion@axes.com <b>sub</b> Free, Get, Off &nbsp;&nbsp;&nbsp; <a href='#' className='linkview'> View More </a></p>
          </div>
        </div>
        <div className='divider'> OR </div>
        <div className="btn-group">
          <button className="add-button" onClick={openPopup}>Add New Email</button>
          <Acclogin isOpen={isPopupOpen} onClose={closePopup} />
          <button className="remove-button">Remove This Email</button>
        </div>
      </div>
    </div>
  );
};

export default ManageEmailPopup;
