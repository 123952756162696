import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import '../gmail.css';
function GLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [timer, setTimer] = useState(60);

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  const handleLoginClick = () => {
    alert(`Email: ${email}, Password: ${password}, OTP: ${otp}`);
    // Implement the login functionality here
  };

  return (
    <div className="login-container">
      <h1>GMAIL</h1>
      <div className="input-group">
        <label>Email</label>
        <input
          type="email"
          placeholder='user@gmail.com'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      <div className="input-group">
        <label>Password</label>
        <input
          type="password"
          placeholder='Password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </div>
      <div className="otp-input-group">
        <label>OTP </label>
        <input 
          type="text"
          placeholder='G - XXXXXX   '
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          maxLength={6}
          required
        />  <b>{timer}s</b>
      </div>
      <button className="login-button-google" onClick={handleLoginClick}>
        <img src="https://img.icons8.com/?size=100&id=17949&format=png&color=000000" width="30px" alt="Gmail Icon" />
        CONNECT
      </button>
    </div>
  );
}

export default GLogin;