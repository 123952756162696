import '../login.css';
import logo from '../logo.png';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        alert(`Email: ${email}, Password: ${password}`);
        navigate("/dashboard"); // Redirects to the terms page after form submission
    };

    return (
        <div className="">
            <div className='App-logo'> <img src={logo} alt="logo" /> </div>
            <h2>LOGIN</h2>
            <form onSubmit={handleSubmit} className="login-form">
                <div className="input-group">
                    <label>USERNAME</label>
                    <input
                        type="email"
                        placeholder="@username"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="input-group">
                    <label>PASSWORD</label>
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <div className="butn"><button type="submit" className="login-button">Login</button></div>
            </form>
            <div className="bottomtxt"> don't have an account <a href="#"> Click Here </a></div>
        </div>
    );
}

export default Login;
