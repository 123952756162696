import React, { useState, useEffect } from 'react';
import { FaGoogle, FaYahoo, FaMicrosoft, FaEnvelopeOpen } from 'react-icons/fa';
import '../acclogin.css';
import NewEmailPopup from './NewEmailPopup';

const AddEmailPopup = ({ isOpen, onClose }) => {
  const [selectedProvider, setSelectedProvider] = useState('gmail');
  const [otpTimer, setOtpTimer] = useState(60);
  const [timerActive, setTimerActive] = useState(false);
  const [showNewEmailPopup, setShowNewEmailPopup] = useState(false);

  useEffect(() => {
    let interval;
    if (timerActive && otpTimer > 0) {
      interval = setInterval(() => {
        setOtpTimer((prevTime) => prevTime - 1);
      }, 1000);
    } else if (otpTimer === 0) {
      clearInterval(interval);
      setTimerActive(false);
    }
    return () => clearInterval(interval);
  }, [timerActive, otpTimer]);

  const handleProviderChange = (e) => {
    setSelectedProvider(e.target.value);
    if (e.target.value === 'imap') {
      setShowNewEmailPopup(true); 
    }
  };

  const startOtpTimer = () => {
    setOtpTimer(60);
    setTimerActive(true);
  };

  const renderIcon = () => {
    if (selectedProvider === 'gmail') {
      return <FaGoogle />;
    } else if (selectedProvider === 'outlook') {
      return <FaMicrosoft />;
    } else if (selectedProvider === 'hotmail') {
      return <FaMicrosoft />;
    } else if (selectedProvider === 'yahoo') {
      return <FaYahoo />;
    }
    return null;
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="popup-overlay">
        <div className="popup">
          <div className="popup-header">
            <h2 className='set-headp'>Add Email</h2>
            <button className="close-button" onClick={onClose}>
              &times;
            </button>
          </div>
          <div className="popup-content">
            <div className="form-group">
              <label htmlFor="emailProviderDropdown">Select Email Provider:</label>
              <div className='dropbtn'>
              <select
                id="emailProviderDropdown"
                className="dropdown"
                value={selectedProvider}
                onChange={handleProviderChange}
              >
                <option value="gmail">Gmail</option>
                <option value="outlook">Outlook</option>
                <option value="yahoo">Yahoo</option>
                <option value="hotmail">Hotmail</option>
                <option value="imap">IMAP</option>
              </select>
              <button className="select-button">Select</button>
              </div>
            </div>

            <form className="email-form">
              <div className="form-group fielddiv">
                <label htmlFor="email">Email:</label>
                <input type="email" id="email" placeholder="Enter your email" />
              </div>
              <div className="form-group fielddiv">
                <label htmlFor="password">Password:</label>
                <input type="password" id="password" placeholder="Enter your password" />
              </div>
              <div className="form-group fielddiv">
                <label htmlFor="otp">OTP:</label>
                <input className="otpf" type="text" id="otp" placeholder="Enter OTP" disabled={!timerActive} />
                <button type="button" className="otp-button" onClick={startOtpTimer}>
                  {timerActive ? `${otpTimer}s` : 'Resend OTP'}
                </button>
              </div>
              <button type="button" className={`submit-button ${selectedProvider}`}>
                {renderIcon()} Connect
              </button>
            </form>
          </div>
        </div>
      </div>

      {showNewEmailPopup && (
        <NewEmailPopup isOpen={showNewEmailPopup} onClose={() => setShowNewEmailPopup(false)} />
      )}
    </>
  );
};

export default AddEmailPopup;
