import React, { useState } from 'react';
import Logo from '../logo.png';
import Profile from '../profile.jpg';
import { useNavigate } from 'react-router-dom'; 
import './ManageEmailPopup.css';
import ManageEmailPopup from './manageemailpopup';
import '../dashboard.css'; 
import { FaSearch, FaPlus, FaMinus, FaTimes, FaCog, FaSignOutAlt, FaEnvelope, FaUser, FaBars, FaChartBar, FaListAlt, FaChartLine,FaPen } from 'react-icons/fa';


const Sidebar = ({ onSettingsClick, onDashboardClick, onAddNewSubscriptionClick }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeGroup, setActiveGroup] = useState(null); 
  const toggleGroup = (group) => {
    setActiveGroup(activeGroup === group ? null : group);
  };
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div>
      <button className="toggle-button" onClick={toggleSidebar}>
        {isSidebarOpen ? <FaTimes /> : <FaBars />}
      </button>
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`} style={{ backgroundColor: "#CCCCCC" }}>
      <div className='fjf'>
      <div className="logo">
        <img src={Logo} />
      </div>

      <button className="sidebar-btn" onClick={onDashboardClick}>Dashboard</button>
      <button className="sidebar-btn"  onClick={onAddNewSubscriptionClick}>Add New Subscription</button>
      <div className="searchbar">
        <input type="text" placeholder="Search..." />
        <FaSearch className="search-icon" />
      </div>
      <div className='btngrpbox'>
      <div className='btngroup'>
      {[...Array(4)].map((_, i) => (
        <div key={i}>
          <button
            className="group-btn"
            onClick={() => toggleGroup(`group${i + 1}`)}
          >
          {activeGroup === `group${i + 1}` ? <FaMinus /> : <FaPlus />} &nbsp; Group {i + 1} 
          </button>
          {activeGroup === `group${i + 1}` && (
            <div className="sub-links">
              <a href="#" className='active'> <FaPen /> &nbsp; Job Search 1</a>
              <a href="#"> <FaPen /> &nbsp; Job Search 2</a> 
              <a href="#"> <FaPen /> &nbsp; Job Search 3</a>
              <a href="#"> <FaPen /> &nbsp; Job Search 4</a>
            </div>
          )}
        </div>
      ))}
      </div>
      </div>
      </div>
      <div className="bottom-options">
        <button className="bottom-btn" onClick={onSettingsClick}><FaCog /> Settings</button>
        <button className="bottom-btn"><FaSignOutAlt /> Logout</button>
      </div>
    </div>
    </div>
  );
};


const Topbar = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className="topbar">
      <button className="topbar-btn" onClick={openPopup}><FaEnvelope /> &nbsp; Manage Email</button>
      <ManageEmailPopup isOpen={isPopupOpen} onClose={closePopup} />
      <button className="topbar-btn"> <FaListAlt /> &nbsp; Manage Subscription</button>

      <button className="topbar-btn"><FaChartBar /> &nbsp; Report / Summary</button>
      <button className="topbar-btn"><FaChartLine /> &nbsp; Statistics</button>
      <div className="profile-pic">
        <img src={Profile} className='img' />
      </div>
    </div>
  );
};

const AddNewSubscription = () => {
  const [isSubscriptionActive, setIsSubscriptionActive] = useState(false);
  const [isGlobalFilter, setIsGlobalFilter] = useState(false);
  const [filterRows, setFilterRows] = useState([{ id: 1, from: '', condition: '', email: '' }]);

  const toggleSubscription = () => {
    setIsSubscriptionActive(!isSubscriptionActive);
  };

  const toggleGlobalFilter = () => {
    setIsGlobalFilter(!isGlobalFilter);
  };

  const addFilterRow = () => {
    setFilterRows([...filterRows, { id: filterRows.length + 1, from: '', condition: '', email: '' }]);
  };

  const removeFilterRow = (id) => {
    setFilterRows(filterRows.filter(row => row.id !== id));
  };

  return (
    <div className="job-search-setup">
      <div className="hed">
        <div> </div>
        <h1 className='set-head'>Setup - Job Search</h1>
          <div className="form-group esx">
          <label>Subscription Active:</label>
          <label className="toggle-switch">
            <input type="checkbox" checked={isSubscriptionActive} onChange={toggleSubscription} />
            <span className="slider"></span>
          </label>
          </div>
        </div>
      
      <div className='subs-form'>
      <div className="form-group">
        <label htmlFor="subscriptionName">Subscription Name:</label>
        <input id="subscriptionName" type="text" placeholder='Enter Subscription Name' disabled={!isSubscriptionActive} />
      </div>

      <div className="form-group">
        <label htmlFor="emailAccount">Select Email Account:</label>
        
        <div className='sdssw'>
        <select 
              id="emailAccount" 
              // value={selectedEmail} 
              // onChange={handleEmailChange} 
              className="dropdown"
              disabled={!isSubscriptionActive || isGlobalFilter}
            >
              <option value="email1@example.com">abc@gmail.com</option>
              <option value="email2@example.com">ghi@outlook.com</option>
              <option value="email3@example.com">def@imap-email.com</option>
            </select>
        <label className='gbf'>
          <span className='gf'>Global Filter:</span>
          <input type="checkbox" checked={isGlobalFilter} onChange={toggleGlobalFilter} />
        </label>
        </div>
      </div>

      <div className="form-group sdss">
        <label>Apply Filter When:</label>
        <div className='radiothree'>
          <label>
            <input type="radio" name="filter" value="matchAll" /> <span> Match all of the following </span>
          </label>
          <label>
            <input type="radio" name="filter" value="matchAny" defaultChecked /> <span> Match Any of the following </span>
          </label>
          <label>
            <input type="radio" name="filter" value="matchMessages" /> Match all messages
          </label>
        </div>
      </div>

      <div className="filter-box">
        {filterRows.map((row, index) => (
          <div key={row.id} className="filter-row">
            <select disabled={!isSubscriptionActive}>
              <option value="">From</option>
              {/* Add more options as necessary */}
            </select>
            <select disabled={!isSubscriptionActive}>
              <option value="">Is</option>
              {/* Add more options as necessary */}
            </select>
            <input type="text" placeholder="Enter email" disabled={!isSubscriptionActive} />
            <button
              type="button"
              onClick={addFilterRow}
              disabled={!isSubscriptionActive}
              style={{ marginLeft: '10px' }}
            >
              +
            </button>
            {filterRows.length > 1 && (
              <button
                type="button"
                onClick={() => removeFilterRow(row.id)}
                disabled={!isSubscriptionActive}
                style={{ marginLeft: '5px' }}
              >
                -
              </button>
            )}
          </div>
        ))}
        </div>
      
      <h4>Perform this Action</h4>
      <div className="action-box">
        <div className="form-group">
          <select disabled={!isSubscriptionActive}>
            <option value="">Copy message to</option>
            {/* Add more options */}
          </select>
          <select disabled={!isSubscriptionActive}>
            <option value="">Email</option>
            <option value="new">Create new</option>
          </select>
        </div>
      </div>

      <div className="form-actions">
        <button className="add-button" disabled={!isSubscriptionActive}>Save Subscriptions</button>
        <button className="remove-button" disabled={!isSubscriptionActive}>Remove Subscriptions</button>
      </div>
    </div>
  </div>
  );
};


const Settings = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contact, setContact] = useState('');
  const [date, setDate] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [lang, setLang] = useState('');
  const [zcode, setzcode] = useState('');
  const [theme, settheme] = useState('');
    
  const handleSubmit = (e) => {
    e.preventDefault();
};

const [selectedEmail, setSelectedEmail] = useState("");
const [folderName, setFolderName] = useState("Mail Gleam Subscriptions");
const [pass, setPass] = useState("MailGleam");

const handleEmailChange = (event) => {
  setSelectedEmail(event.target.value);
};

const handleFolderNameChange = (event) => {
  setFolderName(event.target.value);
};   

const [authMethod, setAuthMethod] = useState('mobile');

const handleAuthChange = (event) => {
  setAuthMethod(event.target.value);
};

const handleSaveClick = () => {
  alert(`You Click on Button`);
  // Implement the login functionality here
};
  const [activeTab, setActiveTab] = useState('general');

  const renderContent = () => {
    switch (activeTab) {
      case 'general':
        return <div className='gen-set'>
            <h1 className='set-head'> General Setting </h1>
            <form onSubmit={handleSubmit} className="general-form">
              <div className='prof-inp'>
                
                <div className='inp'>
                <div className="input-set">
                    <label>Name</label>
                    <input
                        type="text"
                        value={name}
                        placeholder="Enter Your Name"
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>

                <div className="input-set">
                    <label>E-Mail</label>
                    <input
                        type="text"
                        value={email}
                        placeholder="Enter Your E-Mail"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                
                <div className="input-set">
                    <label>Contact</label>
                    <input
                        type="number"
                        value={contact}
                        placeholder="Enter Your Contact"
                        onChange={(e) => setContact(e.target.value)}
                        required
                    />
                </div>

                <div className="input-set">
                    <label>DOB</label>
                    <input
                        type="date"
                        value={date}
                        placeholder="Enter Your Contact"
                        onChange={(e) => setDate(e.target.value)}
                        required
                    />
                </div>
                </div>
                <div>
                  <img className='prof' src={Profile} width="80px" />
                </div>
                </div>

                <div className="input-set">
                    <label>Street</label>
                    <input
                        type="text"
                        value={street}
                        placeholder="Enter Your Street"
                        onChange={(e) => setStreet(e.target.value)}
                        required
                    />
                </div>

                <div className="input-set">
                    <label>City</label>
                    <input
                        type="text"
                        value={city}
                        placeholder="Enter Your City"
                        onChange={(e) => setCity(e.target.value)}
                        required
                    />
                </div>

                <div className='half-inp'>
                <div className="input-set">
                    <label>State</label>
                    <input
                        type="text"
                        value={state}
                        placeholder="Enter Your State"
                        onChange={(e) => setState(e.target.value)}
                        required
                    />
                </div>

                <div className="input-set ts">
                    <label>ZIP Code</label>
                    <input
                        type="number"
                        value={zcode}
                        placeholder="Enter Your ZIP Code"
                        onChange={(e) => setzcode(e.target.value)}
                        required
                    />
                </div>
                </div>

                <div className='half-inp'>
                <div className="input-set">
                <label>Language</label>
                    <input
                        type="text"
                        value={lang}
                        placeholder="Enter Your Language"
                        onChange={(e) => setLang(e.target.value)}
                        required
                    />
                </div>

                <div className="input-set ts">
                    <label>Theme</label>
                    <select 
                    value={lang}
                    placeholder="Enter Your Language"
                    onChange={(e) => setLang(e.target.value)}
                    required
                    >
                      <option> Light </option>
                      <option> Dark </option>
                    </select>
                </div>
                </div>
                <div className="s-butn"><button type="submit" onClick={handleSaveClick} className="save-btn"> Save </button></div>
            </form>
        </div>;
      case 'configuration':     
        return <div className="configure-subscription-container">
        <h1 className='set-head'> Configure Subscription Location </h1>

        <p className='txt-conf'>
          <b>Priority Management:</b> With Mail Gleam, you can set priority levels for different types of emails. Important emails are flagged and brought to your immediate attention, while less critical messages are sorted into appropriate folders for later review.
        </p>
        
        <div className="form-group">
          <label htmlFor="emailAccount" className="label">Select Email Account to Store Subscription</label>
          <div className="email-select-container">
            <select 
              id="emailAccount" 
              value={selectedEmail} 
              onChange={handleEmailChange} 
              className="dropdown"
            >
              <option value="email1@example.com">abc@gmail.com</option>
              <option value="email2@example.com">ghi@outlook.com</option>
              <option value="email3@example.com">def@imap-email.com</option>
            </select>
            <button className="plus-button">+</button>
          </div>
        </div>
        
        <div className="form-group">
          <label htmlFor="folderName" className="label">Root Subscription Folder Name</label>
          <input 
            type="text" 
            id="folderName" 
            value={folderName} 
            onChange={handleFolderNameChange} 
            className="input-field"
          />
        </div>
        
        <div className="button-group">
          <button className="manage-email-button" onClick={handleSaveClick}>Manage Email</button>
          <button className="save-button" onClick={handleSaveClick}>Save</button>
        </div>
      </div>;

      case 'security':
        return <div>
            <div className="security-tab-container">
            <h1 className='set-head'> Security Setting </h1>      
      <div className="pass-form">
        <label htmlFor="password" className="label">Password</label>
        <input 
          type="password" 
          id="password" 
          value={pass} 
          className="input-field"
        />
      </div>

      <h3 className="set-head" style={{marginTop: '14px'}}>Two-Factor Authentication</h3>
      <div className='factor-cont'>
        <p>LearnWorlds requires you to protect your account with 2FA. How would you like to receive one-time passwords (OTPs)?</p>
        <div className="auth-methods">
          <div className="auth-option">
            <input 
              type="radio" 
              id="mobileAuth" 
              name="authMethod" 
              value="mobile"
              checked={authMethod === 'mobile'} 
              onChange={handleAuthChange}
              className="radio-button"
            />
              <div className='authlab'>
                <label htmlFor="mobileAuth" className="auth-label">Mobile App Authentication</label>
                <p className='auth-txt'>Use a mobile app like Google Authenticator to generate verification codes,</p>
              </div>            
          </div>

          <div className="auth-option">
            <input 
              type="radio" 
              id="emailAuth" 
              name="authMethod" 
              value="email"
              checked={authMethod === 'email'} 
              onChange={handleAuthChange}
              className="radio-button"
            />
              <div className='authlab'>
                <label htmlFor="mobileAuth" className="auth-label">Email Authentication</label>
                <p className='auth-txt'>Receive verification codes via email.</p>
              </div>            
          </div>
          <div className="s-butn"><button type="submit" onClick={handleSaveClick} className="save-btn"> Save </button></div>
        </div>
      </div>
    </div>
        </div>;
      default:
        return null;
    }
  };
  return (
  <div className="set-cont">
      <div className="tabs">
        <div className='tab-wrap'>
        <button
          className={`tab ${activeTab === 'general' ? 'active' : ''}`}
          onClick={() => setActiveTab('general')} style={{zIndex:29, position:'relative'}}
        >
          General 
        </button>
        </div>

        <button
          className={`tab ${activeTab === 'configuration' ? 'active' : ''}`}
          onClick={() => setActiveTab('configuration')} style={{zIndex:19, position:'relative'}}
        >
          Configuration
        </button>

        <button
          className={`tab ${activeTab === 'security' ? 'active' : ''}`}
          onClick={() => setActiveTab('security')}
        >
          Security
        </button>

      </div>
      <div className="tab-content">
        {renderContent()}
      </div>
    </div>
  );
};



const Content = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };
  return (
    <div className="content">
      <div className='flex-icon'>
        <div className='icon-boxm'>
          <div className='icon-box'>
              <p className='icon-circle' onClick={openPopup}><FaEnvelope /></p>
              <ManageEmailPopup isOpen={isPopupOpen} onClose={closePopup} />
          </div>
        </div>

        <div className='icon-boxm'>
          <div className='icon-box'>
              <p className='icon-circle'><FaListAlt /></p>
          </div>
        </div>
      </div>

      <div className='flex-icon'>
        <div className='icon-boxm'>
          <div className='icon-box'>
              <p className='icon-circle'><FaChartBar /></p>
          </div>
        </div>

        <div className='icon-boxm'>
          <div className='icon-box'>
              <p className='icon-circle'><FaChartLine /></p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const [activeBlock, setActiveBlock] = useState('dashboard');

  const handleDashboardClick = () => {
    setActiveBlock('dashboard');
  };

  const handleSettingsClick = () => {
    setActiveBlock('settings');
  };

  const handleAddNewSubscriptionClick = () => {
    setActiveBlock('addNewSubscription');
  };

  
  return (
    <div className="dashboard">
       <Sidebar 
        onSettingsClick={handleSettingsClick} 
        onDashboardClick={handleDashboardClick} 
        onAddNewSubscriptionClick={handleAddNewSubscriptionClick} 
      />
       
      <div className="main-content">
        <Topbar />
        {activeBlock === 'dashboard' && <Content />}
        {activeBlock === 'settings' && <Settings />}
        {activeBlock === 'addNewSubscription' && <AddNewSubscription />}
      </div>
    </div>
  );
};

export default Dashboard;
